import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import trad_front from '../../../Assets/Images/trad_front.png';
import red_group from '../../../Assets/Images/red_group.png';
import red_crown from '../../../Assets/Images/red_crown.png';
import redcross from '../../../Assets/Images/add.png';
import black_filter from '../../../Assets/Images/black_filter.png';
import leave_white from '../../../Assets/Images/leave_white.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetNoTradManga } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { GetBooksClaimed, GetPostulatedMembers, GetSearchUser, GetTranslatorTeamMembers, IGetPostulatedMembers, IGetSearchUser, InviteMemberRequest, ITranslationInValidation, LeaveTeam, PostTranslatorLeaveTeam, PostTranslatorTeamFire, PostTranslatorTeamGiveLead, PostTranslatorTeamInvite, PostTranslatorTeamModify, PostTranslatorTeamPostulatedAuthorize, PostTranslatorTeamWaitingClaimed, RequestBookClaimed, TeamRequest, TranslationInValidation } from '../../../Api/Team';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';

const MyTeamInterface: React.FC = () => {
    const {user, setmyteam, myteam} = useContext(MainContext);
    const [teamLeader, setTeamLeader] = useState<User | undefined>(undefined);
    const [subMenu, setSubMenu] = useState<string>('claimed');
    const [open, setOpen] = useState<boolean>(false);
    const [searchoeuvre, setsearchoeuvre] = useState<IOeuvre[]>([]);
    const [mytraduction, setmytraduction] = useState<ITranslatedBook[]>([]);
    const [myclaimwaiting, setmyclaimwaiting] = useState<ITranslatedBook[]>([]);
    const [TranslationInValidationData, setTranslationInValidationData] = useState<ITranslationInValidation[]>([]);
    const [members, setMembers] = useState<ITranslationInValidation[]>([]);
    const [postulants, setPostulants] = useState<IGetPostulatedMembers[]>([]);
    const [openPostulant, setOpenPostulant] = useState<boolean>(false);
    const [inviteOpen, setInviteOpen] = useState<boolean>(false);
    const [searchUser, setSearchUser] = useState<string>('');
    const [users, setUsers] = useState<IGetSearchUser[]>([]);
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchUser === '')
            return;

        GetSearchUser(searchUser).then((res) => {
            setUsers(res);
        });
    
    }, [searchUser]);

    useEffect(() => {
        if (!user)
            navigate('/login');

        if (!myteam)
            navigate('/discover');

        if (myteam) {
            getUser(myteam.user_leader_id).then((u) => { setTeamLeader(u); });

            GetTranslatorTeamMembers(myteam.team_ID).then((res) => {
                setmyteam({...myteam, members: res});
            });

            GetBooksClaimed(myteam.team_ID).then((response) => {
                if (response.status === 200)
                    setmytraduction(response.data);
            });

            PostTranslatorTeamWaitingClaimed(myteam.team_ID).then((res) => {
                setmyclaimwaiting(res);
            });

            TranslationInValidation(myteam.team_ID).then((res) => {
                if (res.status === 200 && res.data) {
                    setTranslationInValidationData(res.data);
                }
            });

            GetPostulatedMembers(myteam.team_ID).then((res) => {
                setPostulants(res);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {onEdit && <div className='absolute z-20 w-full h-full bg-black bg-opacity-70 flex items-center justify-center'>
            </div>}
            {inviteOpen && <div onClick={(e) => {
                e.stopPropagation();
                setInviteOpen(false);
            }} className='absolute w-full z-10 bg-opacity-60 h-full bg-black flex items-center justify-center'>
                <div onClick={(e) => {
                    e.stopPropagation();
                }} className='relative w-[550px] h-[550px] bg-gray-200 flex flex-wrap gap-4 rounded-lg p-4 overflow-y-scroll'>
                    <input type="text" value={searchUser}  placeholder='Rechercher un utilisateur' onChange={(e) => {
                        setSearchUser(e.target.value);
                    }} className='relative w-full h-10 p-2 bg-white rounded-lg outline-none' />
                    {users.map((u, index) => {
                        return (
                            <div key={index} className='relative w-full h-20 flex justify-between items-center gap-4 p-2 bg-white rounded-lg'>
                                <img src={backend_url + u.profile} className='w-14 h-14 object-cover rounded-full' />
                                <h1 className='text-sm Montserrat-SemiBold'>{u.pseudo}</h1>
                                <button onClick={() => {
                                    if (myteam && user)
                                        PostTranslatorTeamInvite(myteam.team_ID, u.user_ID).then((res) => {
                                            if (res.status === 200)
                                                setInviteOpen(false);
                                        });
                                }} className='relative p-2 bg-gatek_red-500 rounded-md text-white Montserrat-SemiBold'>Inviter</button>
                            </div>
                        );
                    })}
                </div>
            </div>}
            {open && <div onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
            }} className='absolute w-full z-10 bg-opacity-70 h-full bg-gray-200 flex items-center justify-center'>
                <div onClick={(e) => {
                    e.stopPropagation();
                }} className='relative w-[80%] h-[80%] bg-white rounded-lg p-4'>
                    <div className='relative h-[8%] flex gap-4 items-center'>
                        <img src={black_filter} className='h-[50%] object-cover' />
                        <select id="selected_language" onChange={(e) => {
                            e.stopPropagation();
                            GetNoTradManga(e.target.value).then((res) => {
                                setsearchoeuvre(res);
                            });
                        }} className='relative p-1 w-[20%] h-[60%] outline-none text-black Poppins-SemiBold text-sm border-2 border-gray-400 border-opacity-40 rounded-xl'>
                            {AllWorldLanguages.map((langue, _) => {
                                return (
                                    <option value={langue}>{langue}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className='relative h-[90%] flex flex-wrap gap-4 items-center overflow-y-scroll overflow-x-hidden'>
                        {searchoeuvre.map((oeuvre, index) => {
                            return (
                                <div key={index} className='relative w-[240px] h-[340px]'>
                                    <img src={backend_url + oeuvre.cover} className='w-full h-[90%] object-cover' />
                                    <h1 className='absolute bottom-[10%] p-2 text-sm bg-black bg-opacity-60 w-full text-white Montserrat-SemiBold'>{oeuvre.name}</h1>
                                    <div className='relative w-full h-[10%] flex items-center justify-center'>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            const lang = (document.getElementById('selected_language') as HTMLSelectElement).value;
                                            console.log(lang, myteam);
                                            if (myteam && lang)
                                                RequestBookClaimed(oeuvre.id, myteam.team_ID, lang).then((res) => {
                                                    if (res.status === 200) {
                                                        let _d = searchoeuvre.filter((o) => o.id !== oeuvre.id);
                                                        setsearchoeuvre(_d);

                                                        PostTranslatorTeamWaitingClaimed(myteam.team_ID).then((res) => { setmyclaimwaiting(res); });
                                                        setOpen(false);
                                                    }
                                                });
                                        }} className='relative w-1/2 h-[90%] rounded-lg bg-gatek_red-500 text-white Montserrat-SemiBold'>Claim</button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>}

            {openPostulant && <div onClick={(e) => {
                e.stopPropagation();
                setOpenPostulant(false);
            }} className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                <div onClick={(e) => {
                e.stopPropagation();
            }} className='bg-gray-200 w-[30%] h-[80%] rounded-xl flex flex-col items-center justify-between p-4 overflow-y-scroll'>
                    {postulants.map((postulant, index) => {
                        return (
                            <div key={index} className='relative w-full rounded-xl flex flex-col h-[184px] gap-4 bg-white p-2'>
                                <div className='relative w-full h-[20%] flex items-center gap-4'>
                                    <img src={logo_gatek} className='object-cover h-[40px] rounded-full' />
                                    <h1 className='Montserrat-SemiBold'>{postulant.applicant_name}</h1>
                                </div>
                                <textarea className='w-full text-sm h-[40%] border-b-2 border-gray-300 outline-none resize-none bg-transparent' value={postulant.message}></textarea>
                                <div className='relative w-full h-[20%] flex gap-4'>
                                    <button onClick={() => {
                                        if (user && myteam)
                                            PostTranslatorTeamPostulatedAuthorize(user?.id, myteam?.team_ID, false, "REFUSED", postulant.applicant_id).then((res) => {
                                                if (res.status === 200) {
                                                    let _d = postulants.filter((p) => p.applicant_id !== postulant.applicant_id);
                                                    setPostulants(_d);
                                                    setOpenPostulant(false);
                                                    GetTranslatorTeamMembers(myteam.team_ID).then((res) => {
                                                        setmyteam({...myteam, members: res});
                                                    });
                                                }
                                            });
                                    }} className='w-full h-full rounded-xl bg-white text-black border-2 border-black Montserrat-SemiBold'>Refuser</button>
                                    <button onClick={() => {
                                       if (user && myteam)
                                        PostTranslatorTeamPostulatedAuthorize(user?.id, myteam?.team_ID, true, "ACCEPTED", postulant.applicant_id).then((res) => {
                                            if (res.status === 200) {
                                                let _d = postulants.filter((p) => p.applicant_id !== postulant.applicant_id);
                                                setPostulants(_d);
                                                setOpenPostulant(false);
                                                GetTranslatorTeamMembers(myteam.team_ID).then((res) => {
                                                    setmyteam({...myteam, members: res});
                                                });
                                            }
                                        });
                                    }} className='w-full h-full rounded-xl bg-gatek_red-500 text-white Montserrat-SemiBold'>Accepter</button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>}

            <div className='relative w-full h-full overflow-x-hidden overflow-y-scroll'>
                <div className='relative w-full h-[30%]'>
                <div className='absolute w-full h-full bg-black bg-opacity-80'></div>
                <img src={trad_front} className='absolute h-full object-cover -z-10' />
                    <div className='relative h-[20%]'>
                        <Navbar />
                    </div>
                </div>
                <div className='relative w-full h-[10%]'>
                    <div className='absolute left-[8%] -top-[100%] gap-2 h-[200%] flex w-[40%]'>
                        <img src={backend_url+myteam?.team_profile} className='relative w-[170px]  h-[170px] border-white bg-gray-900 m-auto rounded-full border-8' />
                        <div className='relative self-end text-left w-[100%] h-[50%] flex flex-col gap-2'>
                            {!onEdit ? <h1 className=' Montserrat-SemiBold transform text-4xl truncate'>{myteam?.team_name}</h1> :
                            <input value={myteam?.team_name} onChange={(e) => {
                                if (myteam)
                                    setmyteam({...myteam, team_name: e.target.value});
                            }} className='text-4xl z-20 Montserrat-SemiBold outline-none' />}
                            <button onClick={() => {
                                if (myteam?.team_ID)
                                    navigate('/team?id='+myteam.team_ID);
                            }} className='relative text-sm p-2 w-[70%] px-4 bg-gatek_red-500 text-white Montserrat-Regular rounded-lg'>Voir profil</button>
                        </div>
                    </div>
                    <div className='absolute right-[8%] top-0'>
                        <div className='relative w-full h-1/2 flex gap-4 items-center'>
                            <img src={red_crown} className='h-full object-contain' />
                            <h1 className='text-2xl Montserrat-SemiBold'>{teamLeader?.username}</h1>
                        </div>
                        <div className='relative w-full h-1/2 flex gap-4 items-center'>
                            <img src={red_group} className='h-full object-contain' />
                            <h1 className='text-2xl Montserrat-SemiBold'>{myteam?.members?.length}</h1>
                        </div>
                    </div>
                </div>
 
                <div className='relative w-full px-[8%] mt-4 flex flex-wrap justify-end'>
                    <h1 className='text-4xl Montserrat-SemiBold p-2 w-full'>A propos</h1>
                    {!onEdit ? <p className='text-sm Montserrat-Regular p-2 w-full'>{myteam?.description}</p> :
                    <textarea value={myteam?.description} onChange={(e) => {
                        if (myteam)
                            setmyteam({...myteam, description: e.target.value});
                    }} className='text-sm Montserrat-Regular resize-none z-20 p-2 w-full h-[200px]'></textarea>}
                    {(myteam?.user_leader_id === user?.id) && <button onClick={() => {
                        if (myteam && onEdit && myteam.team_name && myteam.description)
                            PostTranslatorTeamModify(myteam.team_name, myteam.description).then((res) => {
                                if (res.status === 200)
                                    setOnEdit(!onEdit);
                            });
                        setOnEdit(!onEdit);
                    }}className={`relative p-2 text-gatek_red-500 ${onEdit && "z-20 bg-white"} underline Montserrat-SemiBold rounded-lg self-end`}>
                        {onEdit ? 'Save' : 'Editer'}
                    </button>}
                </div>

                <div className='relative w-full px-[8%] mt-4'>
                    <div className='bg-gray-200 rounded-2xl'>
                        <div className='relative h-[10%] p-4'>
                            <button onClick={() => {
                                setSubMenu('claimed');
                            }} className={`relative text-xl Montserrat-SemiBold p-2 cursor-pointer px-16
                                ${subMenu === "claimed" && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                            `}>oeuvre(s) réservée(s)</button>
                            <button onClick={() => {
                                setSubMenu('team');
                            }} className={`relative text-xl Montserrat-SemiBold p-2 cursor-pointer px-16
                                ${subMenu === "team" && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                            `}>équipe</button>
                            <button onClick={() => {
                                setSubMenu('translation');
                            }} className={`relative text-xl Montserrat-SemiBold p-2 cursor-pointer px-16
                                ${subMenu === "translation" && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                            `}>traduction en attente</button>
                        </div>

                        {subMenu === 'claimed' && <div className='relative w-full h-full p-4 flex flex-wrap gap-4'>
                            <h1 className='relative Montserrat-SemiBold text-xl border-b-2 border-black w-full'>En attente de claim</h1>
                            {myclaimwaiting.length <= 0 && <h1 className='italic text-center w-full Montserrat-Regular'>Pas de claim en attente</h1>}
                            <div className='relative w-full h-full flex flex-wrap gap-4'>
                                {myclaimwaiting.map((trad, index) => {
                                    return (
                                        <div onClick={(e) => {
                                        }} key={index} className='relative w-[240px] h-[340px] flex flex-col gap-4'>
                                            <img src={backend_url + trad.manga_cover} className='w-full h-[90%] object-cover' />
                                            <h1 className='text-sm Montserrat-SemiBold w-full truncate'>{trad.manga_name}</h1>
                                        </div>
                                    );
                                })}
                            </div>

                            <h1 className='relative Montserrat-SemiBold text-xl border-b-2 border-black w-full'>Vos Claims</h1>
                            <div className='relative w-full h-full flex flex-wrap gap-4'>
                                <div onClick={() => {
                                    setOpen(true);
                                }} className='border-2 border-dashed border-red-700 w-[240px] h-[340px] flex flex-col items-center justify-center transform duration-150 hover:scale-95 cursor-pointer'>
                                    <img src={redcross} className='h-[8%]' />
                                    <h1 className='text-sm Montserrat-SemiBold text-red-700'>Claim</h1>
                                </div>
                                {mytraduction.map((trad, index) => {
                                    return (
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            navigate('../translate?id='+trad.manga_id+"&lang="+trad.language);
                                        }} key={index} className='relative w-[240px] h-[340px] flex flex-col gap-4 transform duration-150 hover:opacity-70 cursor-pointer'>
                                            <img src={backend_url + trad.manga_cover} className='w-full h-[90%] object-cover' />
                                            <h1 className='text-sm Montserrat-SemiBold w-full truncate'>{trad.manga_name} ({trad.language})</h1>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>}

                        {subMenu === 'team' && <div className='relative w-full h-full p-4 flex flex-wrap gap-4'>
                            <div className='relative w-full h-[20%] flex items-end justify-end gap-4 border-b-2 border-gray-400 border-opacity-40 p-4'>
                                {postulants.length > 0 ? <button onClick={(e) => setOpenPostulant(true)} className='relative p-2 border-2 border-gatek-400 h-[90%] rounded-lg bg-white text-gatek-400 Montserrat-SemiBold'>
                                    <h2 className='absolute -right-3 -top-3 text-sm rounded-full bg-gatek-400 text-white Montserrat-Regular w-6 h-6 flex items-center justify-center'>{postulants.length}</h2>
                                    postulant
                                </button> : <button className='relative p-2 h-[90%] rounded-lg bg-gray-300 text-gray-400 Montserrat-SemiBold'>
                                    postulant
                                </button>}
                                <button onClick={() => {
                                    setInviteOpen(true);
                                }} className='relative p-2 h-[90%] rounded-lg bg-red-700 text-white Montserrat-SemiBold'>inviter traducteur</button>
                            </div>
                            <div className='relative w-full h-[80%] flex flex-wrap gap-2'>
                                {myteam?.members?.map((member, index) => {
                                    return (
                                        <div key={index} className='relative w-full flex gap-4 items-center hover:bg-gray-300 hover:bg-opacity-50 p-2'>
                                            <div className='rounded-full overflow-hidden h-[50px]'>
                                                <img src={backend_url + member.profile} className='w-full h-full object-contain' />
                                            </div>
                                            <h1 className='text-sm Montserrat-SemiBold w-full truncate'>{member.user_name}</h1>
                                            <button onClick={() => {
                                                PostTranslatorTeamGiveLead( member.user_ID).then((res) => {
                                                    if (res.status === 200) {
                                                        GetTranslatorTeamMembers(myteam.team_ID).then((res) => {
                                                            setmyteam({...myteam, members: res});
                                                        });
                                                    }
                                                });
                                            }} className='relative p-2 bg-red-700 rounded-xl h-[90%] text-xs text-white Montserrat-SemiBold'>Promouvoir chef</button>
                                            <button onClick={() => {
                                                PostTranslatorTeamFire(myteam?.team_ID, member.user_ID).then((res) => {
                                                    if (res.status === 200) {
                                                        GetTranslatorTeamMembers(myteam.team_ID).then((res) => {
                                                            setmyteam({...myteam, members: res});
                                                        });
                                                    }
                                                });
                                            }} className='relative p-2 bg-red-700 rounded-xl h-[90%] text-xs text-white Montserrat-SemiBold'>Virer</button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                </div>

                <div className='relative w-full px-[8%] mt-8 flex flex-col gap-4 py-4 items-end'>
                    <div className='border-t-2 border-gray-300 w-full'></div>
                    <button onClick={() => {
                        if (user && myteam)
                            PostTranslatorLeaveTeam(myteam?.team_ID, user?.id).then((res) => {
                                if (res.status === 200)
                                    navigate('/discover');
                            });
                    }} className='relative w-[10%] px-8 p-2 bg-gatek_red-500 text-white Montserrat-SemiBold rounded-full flex items-center justify-center gap-2'>
                        <img src={leave_white} className='w-5 h-5 object-contain' />
                        Quitter
                    </button>
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default MyTeamInterface;