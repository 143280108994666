import React, {useContext, useEffect, useState} from 'react';
import red_check from '../../../Assets/Images/red_check.png';
import market_white from '../../../Assets/Images/market_white.png';
import disconnect from '../../../Assets/Images/disconnect.png';
import black_coin from '../../../Assets/Images/black_coin.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetAuthorSupportList, GetPayementCancel, GetSubscriptions, IGetAuthorSupportList, ISubscription, modifyUserInfo, PostAuthorSupportUnsupport, PostPaymentRenew, StripePay, SubscriptionType } from '../../../Api/User';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import Navbar from '../../Intra/Discovered/Navbar';
import { GetUsers } from '../../../Interfaces/User';
import { AllWorldLanguages } from './TraductorEditInterface';

const Subs: React.FC = () => {
    const {user, setuser, setToken, language, setlanguage} = useContext(MainContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [imgOpen, setImgOpen] = useState(false);
    const [modify, setModify] = useState<string | undefined>(undefined);
    const [subscriptions, setSubscriptions] = useState<IGetAuthorSupportList[]>([]);
    const [mySub, setMySub] = useState<ISubscription | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (user)
            GetSubscriptions(user?.id).then((res) => {
                if (res.status === 200 && res.data) {
                    setMySub(res.data);
                }
            });

    }, [user]);

    useEffect(() => {
        GetAuthorSupportList().then((res) => {
            if (res) {
                setSubscriptions(res);
            }
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-y-auto bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative flex flex-col gap-4 py-4 px-[8%]'>
                    <h1 className='text-3xl Montserrat-Bold'>Soutien et abonnement</h1>

                    <h1 className='Montserrat-Regular border-b-2 border-black py-2'>Offre d’abonnement</h1>
                    <div className='bg-white w-[550px] h-[410px] self-center rounded-xl border-2 border-gray-300 flex flex-col gap-4 justify-center items-center'>
                        <h1 className='text-7xl PermanentMarker-Regular text-gatek-400'>PRO</h1>
                        <h1 className='text-lg Montserrat-SemiBold '>9,99 $/mois</h1>
                        <h1 className='text-lg Montserrat-Regular text-center'>Avec abonnement PRO, accéder à la fonctionnalité de <span className='text-gatek-400 Montserrat-SemiBold'>création d’équipe</span> mais aussi à nos <span className='text-gatek-400 Montserrat-SemiBold'>outils de traduction</span>.</h1>
                        <div className='relative flex flex-col gap-2 p-2 text-sm'>
                            <h1 className='relative Montserrat-SemiBold'><img src={red_check} alt='check' className='w-6 h-6 inline-block' /> Accès à la création d’équipe</h1>
                            <h1 className='relative Montserrat-SemiBold'><img src={red_check} alt='check' className='w-6 h-6 inline-block' /> Gestion d’équipe</h1>
                            <h1 className='relative Montserrat-SemiBold'><img src={red_check} alt='check' className='w-6 h-6 inline-block' /> Claim d’oeuvres</h1>
                            <h1 className='relative Montserrat-SemiBold'><img src={red_check} alt='check' className='w-6 h-6 inline-block' /> Outils de traduction</h1>
                        </div>
                    </div>
                    {(mySub && mySub.currents.length > 0 && mySub.currents[0].abonnementType === "subscription_advanced") ? <button onClick={() => {
                        GetPayementCancel().then((res) => {
                            if (res) {
                                GetSubscriptions(user?.id).then((res) => {
                                    if (res.status === 200 && res.data) {
                                        setMySub(res.data);
                                    }
                                });
                            }
                        });
                    }} className='border border-gatek-400 bg-white text-gatek-400 px-4 py-2 rounded-lg Montserrat-SemiBold flex gap-2 items-center justify-center self-center'>
                        Arreter mon abonnement
                    </button> : (mySub && mySub.previous.length > 0 && mySub.previous[0].abonnementType === "subscription_advanced") ? <button onClick={() => {
                        PostPaymentRenew(mySub.previous[0].subscription_ID).then((res) => {
                            if (res) {
                                GetSubscriptions(user?.id).then((res) => {
                                    if (res.status === 200 && res.data) {
                                        setMySub(res.data);
                                    }
                                });
                            }
                        });
                    }} className='bg-gatek-400 text-white px-4 py-2 rounded-lg Montserrat-SemiBold flex gap-2 items-center justify-center self-center'>
                        <img src={market_white} alt='coin' className='w-6 h-6 inline-block' />
                        Renouveler mon abonnement
                    </button> : <button onClick={() => {
                        StripePay(user?.id, SubscriptionType.subscription_advanced).then((res) => {
                            if (res) {
                                GetSubscriptions(user?.id).then((res) => {
                                    if (res.status === 200 && res.data) {
                                        setMySub(res.data);
                                    }
                                });
                            }
                        });
                    }} className='bg-gatek-400 text-white px-4 py-2 rounded-lg w-[130px] Montserrat-SemiBold flex gap-2 items-center justify-center self-center'>
                        <img src={market_white} alt='coin' className='w-6 h-6 inline-block' />
                        Payer
                    </button>}

                    <h1 className='Montserrat-Regular border-b-2 border-black py-2'>Soutien</h1>
                    <div className='relative overflow-y-scroll flex flex-wrap px-4 gap-2'>
                        {subscriptions.map((sub: IGetAuthorSupportList, index: number) => {
                            return (
                                <div key={index} className='bg-white self-center rounded-xl border-2 border-gray-300 flex gap-4 justify-between items-center p-2 w-full'>
                                    <img src={backend_url+sub.author_profile_picture} alt='profile' className='w-20 h-20 rounded-full object-cover' onError={handleImageError} />
                                    <h1 className='text-lg Montserrat-SemiBold'>{sub.author_name}</h1>
                                    <button onClick={() => {
                                        PostAuthorSupportUnsupport(sub.author_ID).then((res) => {
                                            if (res) {
                                                GetAuthorSupportList().then((res) => {
                                                    if (res) {
                                                        setSubscriptions(res);
                                                    }
                                                });
                                            }
                                        });
                                    }} className='border border-gatek-400 px-4 py-2 rounded-lg p-4 text-xs Montserrat-SemiBold flex gap-2 items-center justify-center self-center'>
                                        Arreter de soutenir
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Subs;